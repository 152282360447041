<template>
  <div>
    <div className="row">
      <div className="col-xxl-12 order-1 order-xxl-2">
        <TweetTable></TweetTable>
      </div>
    </div>
    <!--end::Dashboard-->
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import TweetTable from "@/view/content/widgets/datatable/TweetTable.vue";
import {UPDATE_ACCOUNT_INFO} from "@/core/services/store/profile.module";

export default {
  name: "dashboard",
  components: {
    TweetTable
  },
  data() {
    return {}
  },
  mounted() {
    if (!this.currentUserAccountInfo.username) {
      this.uploadAccount();
    }
  },
  methods: {
    uploadAccount() {
      this.$store
          .dispatch(UPDATE_ACCOUNT_INFO,)
          .then(() => {
          });
    },
  },
  computed: {
    ...mapGetters(["currentUserAccountInfo"]),
  },
};
</script>
