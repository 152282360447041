<template>
    <div v-if="params.data">
      <div v-if="params.data.reply_list" >
         <a  v-for="(item, i) in params.data.reply_list.split(',')" :href="'https://twitter.com/'+item"  target="_blank">
         <span class="mb-0 font-weight-bold mt-4 text-center">@{{ item }}&nbsp;&nbsp;</span>
       </a>
      <span >{{ params.data.content }}</span>
      </div>
      <span v-else>{{ params.data.content }}</span>
    </div>
</template>

<script>

    export default {
        name: "NameRenderer",
        components: {
        },
        props: {
            params: {
                type: Object,
                required: true
            }
        },
        data() {
            return {

            }
        },
        computed:{

        },
        methods: {

        }
    }
</script>

