<template>
    <div v-if="params.data">
       <a  v-if="params.data.web_archive_url" :href="params.data.web_archive_url" target="_blank">
         <span class="mb-0 font-weight-bold mt-4 text-center">{{ params.data.tid}}</span>
       </a>

       <a  v-else :href="`https://twitter.com/anyuser/status/${params.data.tid}`" target="_blank">
         <span class="mb-0 font-weight-bold mt-4 text-center">{{ params.data.tid}}</span>
       </a>

    </div>
</template>

<script>

    export default {
        name: "NameRenderer",
        components: {
        },
        props: {
            params: {
                type: Object,
                required: true
            }
        },
        data() {
            return {

            }
        },
        computed:{

        },
        methods: {

        }
    }
</script>

