<template>
  <!--begin::Advance Table Widget 9-->
  <div class="card">
    <!--begin::Header-->
    <div class="card-header border-0 py-5 d-flex justify-content-between">
      <div
        class="font-weight-bolder text-primary text-uppercase font-size-h2"
        v-if="tweets_count == 0"
      >
        Tweets
      </div>
      <div
        class="font-weight-bolder text-primary text-uppercase font-size-h2"
        v-else
      >
        Tweets ({{ tweets_count }})
      </div>
      <div class="d-flex">
        <b-form-select v-model="currentUser"  size="md" class="mr-4" multiple style="width: 150px!important;">
          <option
              v-for="(option, idx) in tusers"
              :key="idx"
              :value="option.name"
              :title="option.name"
          >
        {{ option.name }}
      </option>
        </b-form-select>
         <b-form-select v-model="currentIndex"  size="md" class="mr-4" style="width: 150px!important;">
          <option
              v-for="(option, idx) in indexs"
              :key="idx"
              :value="option.name"
              :title="option.name"
          >
        {{ option.name }}
      </option>
        </b-form-select>
        <b-form-input
          v-model="search_text"
          style="width: 400px!important;"
          @change = "updateData"
          placeholder="Search..."
        ></b-form-input>

      </div>
    </div>
    <div class="card-body pt-0 pb-3">
      <ag-grid-vue
        :rowModelType="'serverSide'"
        class="ag-theme-material ag-grid-vue"
        :columnDefs="columnDefs"
        @grid-ready="onGridReady"
        :overlayLoadingTemplate="overlayLoadingTemplate"
         :overlayNoRowsTemplate="overlayNoRowsTemplate"
        :defaultColDef="defaultColDef"
        :frameworkComponents="frameworkComponents"
      >
      </ag-grid-vue>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapState} from "vuex";

import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-material.css";
import { AgGridVue } from 'ag-grid-vue';

import TweetActionsRenderer from './Render/TweetActionsRenderer.js';
import TweetTitleRenderer from './Render/TweetTitleRenderer.js';
import TweetContentRenderer from './Render/TweetContentRenderer.js';
import { LicenseManager } from "ag-grid-enterprise";
import 'ag-grid-enterprise';
import {
  CREATE_TWEET,
  UPDATE_TWEET,
  GET_TWEETS,
  DELETE_TWEET
} from "../../../../core/services/store/tweet.module";

import {
  GET_TUSERS,
} from "../../../../core/services/store/tuser.module";

LicenseManager.setLicenseKey("Servoy_B.V_Servoy_7Devs_200Deployment_11_October_2020__MTYwMjM3MDgwMDAwMA==26d908e26f73b44683ba7a5dfdd00755");

export default {
  name: "transactions",
  components: {
     AgGridVue,
  },
  data() {
    return {
      search_text: '',
      members_count: 0,
      currentUser : [],
      tusers: [],
      currentIndex : 'All',
      indexs: [{name: 'All'}, {name: '0'}, { name : "1"}, { name : "2"}],
      tweets_count : 0,
      gridApi: null,
      columnApi: null,
      overlayLoadingTemplate: null,
      overlayNoRowsTemplate: null,
      defaultColDef: {
        sortable: true,
        suppressMenu: true,
        flex: 1,
      },
      frameworkComponents : null,
    };
  },
  computed: {
    ...mapState({
      errors: state => state.tweet.errors
    }),
    ...mapGetters(["layoutConfig", "currentUserAccountInfo"]),
    columnDefs(){
      return  [
        {
         headerName:'Tweet #',
         field: 'tid',
          maxWidth: 300,
         cellRenderer: 'tweetTitleRenderer',
         cellStyle: {
           textAlign: 'center',
         },
       },
       {
         headerName:'Username',
         field: 'username',
         maxWidth: 150,
         cellStyle: {
           fontWeight: 500,
           textAlign: 'center',
         },
       },
       {
         headerName: 'Tweet',
         field: 'content',
         cellRenderer: 'tweetContentRenderer',
         wrapText: true,
         autoHeight: true,
         cellStyle: {
            lineHeight: '25px',
            fontSize: '14px',
            marginTop: '-3px',
            paddingBottom: '10px'
         },
       },
        {
         headerName: 'Source',
         field: 'source',
         maxWidth: 150,
         cellStyle: {
           textAlign: 'center',
         },
       },
        {
         headerName: 'Tweeted At',
         field: 't_created_at_formatted',
         maxWidth: 200,
         cellStyle: {
           textAlign: 'center',
         },
       },
        {
         headerName: 'Scan Index',
         field: 'scan_index',
         maxWidth: 200,
         cellStyle: {
           textAlign: 'center',
         },
       },
     ];
    },
  },
  methods: {
    autoSizeAll(skipHeader) {
      const allColumnIds = [];
      this.gridColumnApi.getAllColumns().forEach((column) => {
        allColumnIds.push(column.getId());
      });
      this.gridColumnApi.autoSizeColumns(allColumnIds, skipHeader);
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
      this.loadUsers();
      this.updateData();
     },
    updateData(){
      let new_this = this;
      const datasource = {
       getRows(params) {
         console.log(JSON.stringify(params.request, null, 1));
         let formData = params.request;
         formData.tuser = new_this.currentUser;
         formData.index = new_this.currentIndex;
         formData.search = new_this.search_text;
         new_this.$store
          .dispatch(GET_TWEETS, params.request)
          .then((data) => {
            params.successCallback(data.rows, data.last);
            new_this.tweets_count = data.last;
            if(data.last == 0){
              new_this.gridApi.showNoRowsOverlay();
            }
            else{
                new_this.gridApi.hideOverlay();
            }
          })
       }
     };
     this.gridApi.setServerSideDatasource(datasource);
    },
    loadUsers(){
         this.$store
          .dispatch(GET_TUSERS)
          .then((data) => {
              this.currentUser = 'All'
              this.tusers= data;
              this.currentUser = this.tusers.map(entry => entry.name)

          })
      }
  },
  beforeMount() {
     this.frameworkComponents = {
       'tweetActionsRenderer': TweetActionsRenderer,
       'tweetTitleRenderer' : TweetTitleRenderer,
       'tweetContentRenderer' : TweetContentRenderer,
     };
  },
   created() {
    this.overlayLoadingTemplate =
      '<span class="ag-overlay-loading-center">Please wait while your rows are loading</span>';
    this.overlayNoRowsTemplate =
      '<span style="padding: 10px; border: 2px solid #444;">There is no match record</span>';
  },
  watch: {
    currentIndex  (new_value, old_value) {
       this.updateData();
    },
    currentUser(new_value, old_value) {
       this.updateData();
    },
    // search_text(new_value, old_value) {
    //    this.updateData();
    // }
  }
};
</script>
<style>
.ag-header-cell-label {
   justify-content: center;
}
.btn-create {
  margin-left: 10px;
  border-radius: 33px;
  height: 28px;
  padding: 0rem 1rem !important;
}
.btn-create i {
  font-size: 1rem !important;
  padding-right: 0.1rem !important;
}
.modal-input {
  height: 45px !important;
}
.ag-grid-vue {
  width: 100% !important;
  height: calc(100vh - 230px) !important;
}

/* This CSS is to not apply the border for the column having 'no-border' class */
.no-border.ag-cell:focus {
  border: none !important;
  outline: none;
}
.ag-cell-focus,
.ag-cell-no-focus {
  border: none !important;
}

.ag-row-selected {
  background-color: transparent !important;
}
.ag-theme-material .ag-details-row {
  padding: 10px 40px 10px 40px !important;
}

div.ag-theme-material div.ag-row {
  font-size: 15px !important;
  padding-top: 10px !important;
}
.container {
  max-width: 98vw !important;
}

</style>
